import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Main from "./main";
import Footer from "../footer";
const Bukhara = () => {
  return (
    <>
      <Main />
      <div className="cotainer">
        {" "}
        <div className="more-comp-bottom">
          <div className="more-comp-right">
            <h1>Tashkent-Bukhara-Samarkand-Tashkent</h1>
            <div className="more-travel-about">
              <p>
                Treasures of Uzbekistan: The Great Silk Road. Unforgettable tour
                along the heart of <br /> the Silk Road. The ancient cities
                still keeps the spirit of Alexander's troops, Arab cavalry,
                mongols invasion and Tamerlane's Empire.
              </p>
              <br />
              <div className="zomin-list">
                <img
                  src="https://www.advantour.com/img/uzbekistan/bukhara/bukhara-tours-day-trips.jpg"
                  alt=""
                />
                <img
                  src="https://www.advantour.com/img/uzbekistan/tours/city-tour-bukhara.jpg"
                  alt=""
                />
              </div>
              <p>
                This tour will give you opportunity to visit 9 regions of
                Uzbekistan. You will learn history and culture, traditions and{" "}
                <br />
                customs of land with its own, centuries-old unique
                civilizations.
              </p>{" "}
              <br />
              <p>
                Samarkand, Bukhara, and Khiva, where cultural and spiritual
                values had been long since concentrated, outstanding scientific
                centres and schools were established, architecture,
                craftsmanship, and applied art were flourishing, played the role
                of main urban centres. Creative work and various scientific
                achievements of the local scientists, thinkers, and poets have
                proved to be a valuable contribution to the development of the
                world civilization. Avicenna, al-Khorezmiy, Mirzo Ulugbek,
                Nakshbandi, al-Bukhoriy, at-Termeziy, Beruniy, Alisher Navoiy -
                short list of names of prominent figures of Uzbekistan.
              </p>{" "}
            </div>
            <br /> <br />
            <div className="zomin-list">
              <img
                src="https://www.advantour.com/img/uzbekistan/tours/city-tour-bukhara2.jpg"
                alt=""
              />
              <img
                src="https://www.advantour.com/img/uzbekistan/tours/two-day-bukhara-tour.jpg"
                alt=""
              />
            </div>
            <div className="more-travel-about">
              <p>
                <strong>8 days / 7 nights</strong>
              </p>
              <br /> <br />
              <p>
                <strong>Day 1</strong>
              </p>{" "}
              <br />
              <b>Tashkent</b> <br /> <br />
              <p>
                Arrival in Tashkent. Pick up from the airport and transfer to
                hotel. Free time.
              </p>
              <br />
              <p>
                Sightseeing tour across Tashkent (starting time will depend on
                your arrival time):
              </p>{" "}
              <br />
              <ul className="list-style">
                <li>Visit the Memorial for the Victims of Repression.</li>
                <li>Overview the Tashkent TV tower (distant view).</li>
                <li>Central Asian Plov Center.</li>
                <li>Minor Mosque (White Mosque).</li>
                <li>Monument of Courage.</li>
                <li>
                  OldCity, Khast-Imam architectural complex: Kaffal-as-Shoshi
                  Mausoleum, Barak-Khan Madrasah, Khast-Imam Mosque. Mui-Muborak
                  Madrasah – the original Koran of the Caliph Usman.
                </li>
                <li>Chorsu's market (most picturesque market in the city).</li>
                <li>Mustakillik Square (Independence Square).</li>
                <li>Palace of the Grand duke Romanov.</li>
                <li>Theater of Alisher Navoiy (external view).</li>
                <li>Amir Temur's square.</li>
                <li>Tashkent Metro (Underground/Subway).</li>
              </ul>{" "}
              <br />
              <p>
                <strong>19:00</strong>&nbsp;Dinner. Overnight in the hotel.
              </p>{" "}
              <br />
              <br />
              <p>
                <strong>Day 2</strong>
              </p>
              <p>
                <strong>Tashkent- Urgench-Khiva</strong>
              </p>{" "}
              <br />
              <br />
              <div className="zomin-list">
                <img
                  src="https://www.advantour.com/img/uzbekistan/tours/bukhara-tour-3-days.jpg"
                  alt=""
                />
                <img
                  src="https://www.advantour.com/img/uzbekistan/tours/bukhara-day-tour.jpg"
                  alt=""
                />
              </div>
              <p>
                Early morning transfer to airport for the &nbsp;flight to
                Urgench/Khiva, time will be specified. Flight departure time
                will be specified.
              </p>{" "}
              <br />
              <p>
                Arrival in Urgench airport. Transfer to Khiva (30 minutes).
                Check-in hotel. Khiva City Tour:
              </p>
              <br />
              <p>Sightseeing tour across Bukhara:</p>
              <br />
              <ul>
                <li>Samanids Mausoleum.</li>
                <li>Chashma-Ayub Mausoleum.</li>
                <li>Bolo-House Mosque.</li>
                <li>The Ark (Bukhara Emir’s residence).</li>
                <li>
                  Poi-Kalon Ensemble: Mir-Arab Madrasah, Kalyan Minaret,
                  Poi-Kalon Mosque.
                </li>
              </ul>
              <br />
              <p>
                <strong>13:00-14:00</strong>&nbsp;Lunch. Continue the tour:
              </p>
              <br />
              <ul>
                <li>Visit Djuma-Mosque.</li>
                <li>Museum of Khorezmian Music.</li>
                <li>Tash-Hauli Palace.</li>
                <li>Kutlug-Inak-Murad Madrasah.</li>
              </ul>
              <br />
              <p>Free time for shopping.</p>
              <br />
              <p>
                <strong>19:00-20:00</strong>&nbsp;Dinner.
              </p>
              <br />
              <p>Free time. Overnight in hotel.</p> <br />
              <br /> 
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Bukhara;
